export type EnvironmentSettings = {
  production: boolean,
  pageTitlePrefix: string,
  backboneUrl: string,
  signalrUlr: string,
  ciamUrl: string,
  backboneApiKey: string,
  mqttBrokerUrl: string,
}

export class AppSettings {
  public environment: EnvironmentSettings;

  constructor(environmentSettings: EnvironmentSettings) {
    this.environment = environmentSettings;
  }
}
