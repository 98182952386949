import { inject, Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';
import { ModalService } from '@twist/ui/components';
@Injectable({
  providedIn: 'root'
})
export class ToastNotificationsService {

  private toastrService = inject(ToastrService);

  private _modalService = inject(ModalService);


  /** show successful toast */
  success(message?: string, title?: string) {
    this.toastrService.success(message, title)
  }
  /** show info toast */
  info(message?: string, title?: string) {
    this.toastrService.info(message, title)
  }
  /** show warning toast */
  warning(message?: string, title?: string) {
    this.toastrService.warning(message, title)
  }
  /** show error toast */
  error(message?: string, title?: string, error?: Error | null) {
    this.toastrService.error(message, title)
      .onTap.subscribe(() => {
        console.error(error);
    })
  }

}
