import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSettings, EnvironmentSettings } from './settings/app-settings';

@NgModule({
  imports: [CommonModule]
})
export class CoreModule {

  static forRoot(environmentSettings: EnvironmentSettings): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AppSettings, useValue: new AppSettings(environmentSettings) }
      ]
    };
  }

}


