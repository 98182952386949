import { signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { distinctUntilChanged, filter, map } from 'rxjs';

export const ApplicationStore = signalStore(
  { 'providedIn': 'root' },
  withState({
   // debugMode: true
  }),
  withComputed(state => {
    const router = inject(Router);

    const queryParamsSignal = toSignal(router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => document.location.search),
      distinctUntilChanged(),
      map(queryString => new URLSearchParams(queryString)),
    ));

    const debugMode = computed(() => {
      const searchParams = queryParamsSignal();
      if (searchParams != null && searchParams.get('debugmode') != null) {
        return searchParams.get('debugmode') === 'true';
      }
      return false;
    });

    return {
      debugMode
    };
  }),
  withMethods(state => {

      const initialize = () => {
        //
      };

      return {
        initialize
      };
    }
  )
);
