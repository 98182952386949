import { LogProvider } from '@twist/logging';
import { Injectable } from '@angular/core';

@Injectable()
export class ConsoleLogProvider implements LogProvider {
  #withDate(template: string): string {
    return `${new Date().toLocaleTimeString()} | ${template}`;
  }

  trace(template: string, ...optionalParams: any[]): void {
    console.trace(this.#withDate(template), ...optionalParams);
  }

  debug(template: string, ...optionalParams: any[]): void {
    console.debug(this.#withDate(template), ...optionalParams);
  }

  info(template: string, ...optionalParams: any[]): void {
    console.info(this.#withDate(template), ...optionalParams);
  }

  warning(template: string, ...optionalParams: any[]): void {
    console.warn(this.#withDate(template), ...optionalParams);
  }

  error(template: string, ...optionalParams: any[]): void {
    console.error(this.#withDate(template), ...optionalParams);
  }

  fatal(template: string, ...optionalParams: any[]): void {
    console.error(this.#withDate(`💩💩💩💩💩 - ${template}`), ...optionalParams);
  }
}
