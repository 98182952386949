import { EnvironmentProviders, isDevMode, makeEnvironmentProviders } from '@angular/core';
import { LOGGER_PROVIDERS, MIN_LOG_LEVEL } from './logger.service';
import { LogLevel } from './loglevel.enum';
import { ConsoleLogProvider } from './logProviders/console-log-provider';

export function provideLogging(): EnvironmentProviders {
  return makeEnvironmentProviders(
    isDevMode()
      ? [
        {
          provide: LOGGER_PROVIDERS,
          useClass: ConsoleLogProvider,
          multi: true,
        },
        {
          provide: MIN_LOG_LEVEL,
          useValue: isDevMode() ? LogLevel.DEBUG : LogLevel.DEBUG,
        }
      ]
      : [
        {
          provide: LOGGER_PROVIDERS,
          useClass: ConsoleLogProvider,
          multi: true,
        },
        {
          provide: MIN_LOG_LEVEL,
          useValue: isDevMode() ? LogLevel.DEBUG : LogLevel.DEBUG,
        }
      ]
  );
}
