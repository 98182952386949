import { getState, signalStoreFeature, withHooks, withMethods } from '@ngrx/signals';
import { effect, inject, Inject } from '@angular/core';
import { Logger } from '@twist/logging';

export function withLogging(storeName: string) {
  return signalStoreFeature(
    withMethods((state) => {
      const _logger = inject(Logger);

      const logDebug = (template: string, ...optionalParams: any[]) => {
        _logger.debug(`[${storeName}] - ${template}`, ...optionalParams);
      }

      return {
        logDebug
      }
    }),
    withHooks({
      onInit(store) {
        effect(() => {
          const state = getState(store);
          store.logDebug('state changed', state);
        });
      },
    })
  );
}
