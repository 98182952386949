import { inject } from '@angular/core';
import { ToastNotificationsService } from './toast-notifications.service';
import { HttpErrorResponse } from '@angular/common/http';

export abstract class ApiServiceBase {
  protected toastNotificationsService = inject(ToastNotificationsService);

  protected handleErrorAndReject(message: string, err: HttpErrorResponse | null, reject: (reason?: string) => void) {

    console.error('[InstallationProductsService] - Failed to getPossibleInstallationRoutineBlueprints ', err);
    switch (err?.status ?? 0) {
      case 500:
        this.toastNotificationsService.error(message, err?.statusText ?? "Server error", err);
        break;
      // TODO: other error messages
      default:
        this.toastNotificationsService.error(message, err?.statusText ?? "Unexpected error", err);
        break;
    }
    reject();
  }
}
